export { goToAnchor, waitForEvent, throttle, convertSVG, popupWindow, getUrlParameter, globalRedirectURL };

import EventEmitter2 from 'eventemitter2';
import { CountUp } from "countup.js";

if (!window.EVT) {
  window.EVT = new EventEmitter2();
}

const globalRedirectURL = {
  value: ""
};

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1].replace(/\+/g, ' '));
    }
  }
};

/* public function goToAnchor() */
let goToAnchor = ($target) => {
  // use an offset when there is sticky nav
  var headerOffset = $(".site-header").height() + 60;
  if ($target.length) {
    $("html, body").animate({
      scrollTop: $target.attr("id") === "top" ? 0 : $target.offset().top - headerOffset
    }, 900, () => {
      var x = window.scrollX, y = window.scrollY;
      $target.focus();
      window.scrollTo(x, y);
      if ($target.is(":focus")) {
        return false;
      } else {
        $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
        $target.focus(); // Set focus again
        window.scrollTo(x, y);
      }
    });
  }
}

/* public function waitForEvent() to run widget code when in Kentico Admin */
let waitForEvent = (eventName, callbackFn) => {
  if ($("body").attr("data-kentico") === "true") {
    window.EVT.on(eventName, callbackFn);
  }
  else {
    callbackFn();
  }
}

/* public function throttle() to improve performance of resize/scroll listeners */
let throttle = (func, wait, immediate) => {
  var timeout;
  return () => {
    var context = this,
      args = arguments,
      later = () => {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      },
      callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

/* public function convertSVG() to embed svg images as html */
let convertSVG = (ele) => {
  var $img = $(ele)
  var imgID = $img.attr('id')
  var imgClass = $img.attr('class')
  var imgURL = $img.attr('src')

  $.get(imgURL, function (data) {
    var $svg = $(data).find('svg')
    if (typeof imgID !== 'undefined') {
      $svg = $svg.attr('id', imgID)
    }
    if (typeof imgClass !== 'undefined') {
      $svg = $svg.attr('class', imgClass + ' replaced-svg')
    }
    $svg = $svg.removeAttr('xmlns:a')
    if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
    // $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
    }
    $img.replaceWith($svg)
  }, 'xml');
}

/* public function popupWindow() to open a new browser window */
let popupWindow = (url, title, w, h) => {
  w = "500px";
  h = "300px";
  if (title ==  "Facebook"){
    url += "?u=" + window.location.href;
  }
  if (title ==  "Linkedin"){
    url += window.location.href;
  }
  if (title == "Twitter"){
    url += "?url=" + window.location.href;
  }
  if (title == "Email"){
    url += window.location.href;
  }
  if (title != "Email") {
    var left = ($(window).width / 2) - (w / 2);
    var top = ($(window).height / 2) - (h / 2);
    var pop = window.open(url, title, "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" + w + ", height=" + h + ", top=" + top + ", left=" + left);
    pop.focus();
  }
}

/* jQuery plugin functions */
// check if element is anywhere within viewport
$.fn.isInView = function () {
  var $this = $(this),
    $window = $(window),
    elementTop = $this.offset().top,
    elementBottom = elementTop + $this.outerHeight(),
    viewportTop = $window.scrollTop(),
    viewportBottom = viewportTop + $window.height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
}

// check if element is centered in viewport
// TODO combine this with .isInView using a boolean parameter for centered
$.fn.isInCenterView = function() {
  var elementTop = this.offset().top + ($( window ).height() /2);
  var elementBottom = elementTop + this.outerHeight();
  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
}

// countup numbers with animation
$.fn.countup = function(params) {
  // make sure dependency is present
  if (typeof CountUp !== "function") {
    console.error("countUp.js is a required dependency of countUp-jquery.js.");
    return;
  }
  var defaults = {
    startVal: 0,
    decimals: 0,
    duration: 2,
  };
  if (typeof params === "number") {
    defaults.endVal = params.endVal;
  } else if (typeof params === "object") {
    $.extend(defaults, params);
  } else {
    console.error("countUp-jquery requires its argument to be either an object or number");
    return;
  }
  this.each(function(i, elem) {
    const countUp = new CountUp(elem, defaults.endVal,defaults.options);
    countUp.start();
  });
  return this;
};
