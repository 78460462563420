// we use the accessible anchor scroll function for keyboard nav of the feed
import { goToAnchor } from './util.js';

(function () {

  $.fn.loadMoreData = function (_options) {
    // These 3 properties are required to use this function
    // template, dataUrl, sampleDataUrl
    // eventBefore can be used data modification such as sorting and replacing data before inserting.
    // eventAfter can be used for HTML modification. Target HTML class and update.
    // Example:
    // $(".article-list--news").loadMoreData({
    //   template: newsList, // imported hbs template
    //   itemClass: 'news-tote',
    //   dataUrl: '/headwaters/handlers/HeadwatersHcfContent.ashx?feed=news',
    //   sampleDataUrl: '/UHKF/media/sample-data/news.json',
    //   previewDomain: 'client.akanewmedia.com',
    //   btnLoad: '.btn--load-more',
    //   lazyLoad: false,
    //   $lazyToggle: $infiniteCheckBox,
    //   loadAll: false, // this will override firstLoad and loadNum, button must be present
    //   firstLoad: 8,
    //   loadNum: 12,
    //   scrollThreshold: 1.5, // multiple of viewport height at which to load next results in infinite mode
    //   eventBefore: function (data,config,container) {},
    //   eventAfter: function (container) {}
    // });

    // set site global settings here, otherwise set when calling function
    var config = $.extend({}, _options, {
        previewDomain: "local.cfmws.ca",
        speed: 400,
        scrollThreshold: 1.5
      }),
      $container = this, //Storing current this value. Maybe convert this to proxy?
      labelStr = $container.attr("id") + "_label",
      descStr = $container.attr("id") + "_desc",
      hasClass = (typeof config.itemClass === "string" && config.itemClass.indexOf(" ") === -1),
      hasButton = (typeof config.btnLoad === "string" && $(config.btnLoad).length === 1),
      hasFirstNum = (typeof config.firstLoad === "number" && config.firstLoad > 0),
      hasToggle = (typeof config.$lazyToggle === "object" && config.$lazyToggle.length === 1),
      loadAll = typeof config.loadAll === "boolean" && config.loadAll,
      isInfinite;
      //const $btnLoad = $(config.btnLoad).prop("outerHTML");
      $(config.btnLoad).hide();

    //Preload Event
    typeof config.eventPreload === "function" ? config.eventPreload() : "";



    // Check if lazyLoad was provided
    if (hasToggle && config.$lazyToggle.prop("checked")) {
      // If required load more settings provided
      if (hasButton && hasFirstNum && hasClass) {
        // set spinner
        isInfinite = true;

        $.extend(config, {
          iconLoading: "<span class='loading-spinner' aria-hidden='true'></span><span class='show-for-sr'>Loading</span>",
          clsLoading: ".loader"
        });
        $(config.btnLoad).addClass("show-for-sr")
          .before("<div class='" + config.clsLoading.substr(1) + "'>" + config.iconLoading + "</div>");
          $(config.btnLoad).hide();
      }
      else {
        // proceed without lazyLoad
        isInfinite = false;
        $(config.btnLoad).removeClass("show-for-sr");
        console.warn("Infinite scroller requires itemClass, btnLoad, firstNum (optional loadNum). Proceeding without infinite scroll.");
      }
    }
    else {
      // procede without lazyLoad
      isInfinite = false;
      $(config.btnLoad).removeClass("show-for-sr");
      //console.warn("Infinite scroller disabled or $lazyToggle property was missing. Proceeding without infinite scroll.");
    }
    

    // If is an infinite scroller
    if (isInfinite) {
      // if feed label not existing
      if ($container.prev().attr("id") !== labelStr) {
        // add it
        $container.before(
          $("<div/>").attr("id", labelStr).attr("class", "show-for-sr").html("scrollable feed of items")
        );
      }
      // if feed description not existing
      if ($container.next().attr("id") !== descStr) {
        // add it
        $container.after(
          $("<div/>").attr("id", descStr).attr("class", "show-for-sr").html("While in the feed press up for previous item, down for next item, shift-up for top of feed, shift-down for end of feed")
        );
      }
      // set accessibility properties for feed mode
      $container.attr({
        "tabindex": "0",
        "role": "feed",
        "aria-busy": "false",
        "aria-labelledby": labelStr,
        "aria-describedby": descStr
      }).removeAttr("aria-live");

      // add keyboard navigation
      $container.on("keydown", function (e) {
        var $activeEl = $(document.activeElement),
          $prevItem = $container.prev(),
          $nextItem = $container.next();

        $activeEl = $activeEl.hasClass(config.itemClass)
          ? $activeEl
          : $activeEl.closest("."+config.itemClass);
        if (e.key === "ArrowUp") {
          if (e.shiftKey) {
            goToAnchor($prevItem);
          } else {
            goToAnchor($activeEl.prev());
          }
        }
        else if (e.key === "ArrowDown") {
          if (e.shiftKey) {
            goToAnchor($nextItem);
          }
          else {
            goToAnchor($activeEl.next());
          }
        }
      });
    }
    else {
      // set accessibility properties for region mode
      $("#"+labelStr+", #"+descStr).remove();
      $container.attr({
        "role": "region",
        "aria-live": "polite"
      }).removeAttr("aria-busy tabindex aria-labelledby aria-describedby");
      $container.off("keydown");

    }

    // If there is a stashed dataset
    if (typeof window.loadedData === "object") {
      // parse it right away
      return parseData(window.loadedData);
    }
    else {
      // build dataUrl for current environment
      // on local dev machine
      if (window.location.hostname === "localhost") {
        // use window.loadedData approach for test data
        console.warn("Local data object not found. Assign to global var loadedData in HTML.");
      }
      else {
        // set the data source url
        var dataUrl = location.protocol + "//" + (window.location.search.indexOf("preview") !== -1 ? config.previewDomain : location.hostname) + ":" + location.port
        dataUrl += config.dataUrl;
        // request JSON
        $.getJSON(dataUrl).done(function (data) {
          // check if data is is in a property of the object or just root
          window.loadedData = (data && data["data"]) ? data["data"] : data;
          // parse the data
          return parseData(window.loadedData);
        }).fail(function() {
          console.warn("Error loading data.");
          // fire the scroller
          doneProcess();
        });
      }
    }

    function parseData (data) {
      
      // if setup is not for load more, don't process
      if (!(hasButton && hasFirstNum)) {
        $(config.btnLoad).hide().attr("aria-hidden", "true");
        doneProcess();
        return false;
      }

      // clear any existing contents
      $container.contents().remove();
      // Check for data in the data
      if (data.length && Object.keys(data).length) {
        // if a before callback provided
        if (typeof config.eventBefore === "function") {
          // run it and use returned dataset
          data = config.eventBefore(data, config);
        }

        // When infinite scroll enabled
        if (isInfinite) {
          // Notify accessible user that feed is busy
          $container.attr("aria-busy", "true");
        }

        
        if (loadAll) {
          //Load all data
          for (let i = 0; i < data.length;i++) {
            $container.append(config.template(data[i]));
          }
        } else {
          // Pass first set or all of the data through template and add to page
          //var loadnum = hasButton && hasFirstNum ? ((data.length > config.firstLoad) ? config.firstLoad : data.length) : data.length; <--- Not sure why it made to this specification. 
          var loadnum = data.length > config.firstLoad ? config.firstLoad : data.length;
          for (let i = 0; i < loadnum; i++) {
            $container.append(config.template(data[i]));
          }
        }
        

        $("#statusMsg").append(data.length > 0 ? "<p>Results have been updated.</p>" : "<p>No results were found.</p>");

        // When infinite scroll enabled
        if (isInfinite) {
          // Notify accessible user that feed is ready
          $container.attr("aria-busy", "false");
        }


        // If more items to show
        if (data.length > config.firstLoad) {
          // Show the button
          $(config.btnLoad).fadeIn(config.speed);
          // Load More button handler (Reassign click event first to prevent multiple click event)
          $(config.btnLoad).off("click").on("click", function () {
            var count = $container.children().length,
              lnum = typeof config.loadNum === "number" && config.loadNum > 0
                ? config.loadNum
                : config.firstLoad;

            // Check maximum count to load
            if (data.length - count < lnum) {
              lnum = data.length - count;
            }

            // When infinite scroll enabled
            if (isInfinite) {
              // Notify accessible user that feed is busy
              $container.attr("aria-busy", "true");
            }

            // Pass next set or all of the data through template and add to page
            for (let i = count; i < count+lnum; i++) {
              $container.append(config.template(data[i]))
            }

            $("#statusMsg").append("<p>Items have been appended to the results list.</p>")

            // When infinite scroll enabled
            if (isInfinite) {
              // Notify accessible user that feed is ready
              $container.attr("aria-busy", "false");
            }

            // If next click reaches end of data
            if (count + lnum >= data.length) {
              // hide the button
              $(config.btnLoad).hide().attr("aria-hidden", "true");
            }
            
            /*else {
              //if button is beyond the screen, scroll
              const toteH = $container.children().height();
              const contB = $container.height() + $container.offset().top;
              const btnP = Math.floor($(config.btnLoad).offset().top) + $(config.btnLoad).height() + (toteH / 2);
              const sclP = $(window).scrollTop() + $(window).height() + toteH;
              if (btnP > sclP) {
                $(window).scrollTop(contB + $(config.btnLoad).height() - $(window).height() + 120);
              }
              console.log("1:" + btnP +" s:"+ sclP +" H:"+ $(window).height() +" B:"+ contB)
            }*/

            // if an after callback provided
            if (typeof config.eventAfter === "function") {
              // run it for additional html manipulation
              config.eventAfter($container);
            }
            return false;
          });
        }
        
      }
      else {
        console.error("No data in dataset.");
      }

      // fire the scroller
      doneProcess();

    }

    function doneProcess () {
      if (isInfinite) {
        window.loadMoreAnimationFr = window.requestAnimationFrame(smoothScroll);
        $(config.clsLoading).fadeOut();
      }
      else {
        // cancel previous scroller
        window.cancelAnimationFrame(window.loadMoreAnimationFr);
      }
      // if an after callback provided
      if (typeof config.eventAfter === "function") {
        // run it for additional html manipulation
        config.eventAfter($container);
      }
    }

    function smoothScroll () {
      if ($(config.btnLoad).offset().top > 0) {
        var triggerY = Math.floor($(config.btnLoad).offset().top) - $(config.btnLoad).height(),
          scrollY = $(window).scrollTop() + $(window).height()*config.scrollThreshold;

        if (scrollY >= triggerY) {
          $(config.btnLoad).trigger("click");
        }

        if ($container.children().length < window.loadedData.length) {
          window.loadMoreAnimationFr = window.requestAnimationFrame(smoothScroll);
        }
        else {
          window.cancelAnimationFrame(window.loadMoreAnimationFr);
          $(config.btnLoad).hide();
        }
      }
    }
  };
}(jQuery));
