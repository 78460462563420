import './modules/loadMore.js';
import { getUrlParameter, globalRedirectURL } from './modules/util.js';
import discountItem from "../transformations/discountItem.hbs";
import { Discount } from './modules/discounts.js';
import 'magnific-popup';

// var category = $("#category").val();
// var sort = $("#sort").val();
// var distance = $("#distance").val();
var stacked = true;
var vendor = (Discount.GetParam("vendor")) ? Discount.GetParam("vendor") : null;
var vendorName = (Discount.GetParam("vendorName")) ? Discount.GetParam("vendorName") : null;
var isFrench = $("body").hasClass("fr-CA");
var $subFilter = $(".discount-advanced-filter select");
var $filterBtn = $(".filter-btn");
var $locationBtn = $("#use-my-location");
var $panel = $(".discount-advanced-filter__controls");
const localize = require('./modules/localize.js');
const categoryList = [];

const filterLocation = {//Filter location container/get-a-quote
  "input":$("#discountFilter_location"), //jQuery
  "trigger":$(".discount-filter__location"), //jQuery
  "origin":"discountFilter_location", //DOM ID
  "destination":"discountFilter_places", //DOM ID
  "modal":$(".modal--location")
};
const filterInputs = {
    "keyword": $("#discountFilter_keyword"),
    //"type": $(".discount-filter__type select"),
    "location": filterLocation.input,
    "sortby": $(".discount-advanced-filter__controls select.sortby"),
    "distance": $(".discount-advanced-filter__controls select.distance")
};
const filterCategory =  $("#discountFilter_category input[name=selectedOptions]");
const totes = {
  "feed" : "#discounts-feed",
  "grid" : ".discount-grid",
  "distance" : ".discount-tote__distance",
  "date" : ".discount-tote__date",
  "filter" : ".discount-filter",
  "subfilter" : ".discount-advanced-filter",
  "loadmore" : "#loadMoreBtn",
  "loadnum" : 20,
  "firstload" : 40,
  "pager" : ".pager",
  "pagesize" : 260,
};
const actcls = {
  "loading" : "loading",
  "loaded" : "loaded",
  "active" : "active",
  "inactive" : "inactive",
  "noresults" : "no-results",
  "error" : "error-loading"
};
const $filterAction = $(".discount-filter__search");

window.discounts_noResults = $(totes.grid).html();
let [path,qstr] = window.location.href.split("?");
window.discounts_noResults ? window.discounts_noResults = window.discounts_noResults.replace("{{","<a href='" + path + "'>").replace("}}","</a>") : "";

$(filterInputs.keyword).val(getUrlParameter("keyword"));

//Select dropdown option by value
const selectDropdown = ($ddl,val) => {
  $ddl.find("option").each((k,v) => {
    let optval = typeof val === "number" ? parseInt($(v).val()) : $(v).val();
    if (optval === val) {
      $(v).prop("selected",true);
      return false;
    }
  });
};

//Setup data field from querystring (first load)
const setQueryData = () => {
  const query = location.search;

  if (query) {
    let qlist = query.substring(1).split("&");

    let loc = {};
    //Loop the query list and if matches, place to each fields
    //***Note: I can get from GetParam() but it's extra roop.
    qlist.forEach(q => {
      let list =  q.split("=");

      $.each(filterInputs,(k,v) => {
        if (list[0] === "category") {
          $(filterCategory).each(function(){
            if($(this).val() == list[1]){
              $(this).prop("checked","checked");
            }
          });
        }
        //Irregular item for location
        if (k === "location") {
          if (list[0] === "coord") {
            let coord = list[1].indexOf(",") >= 0 ? list[1].split(",") : "";
            if (coord) {
              loc["latitude"] = coord[0];
              loc["longitude"] = coord[1];
              loc["loctype"] = parseInt(coord[2]);
            }
          }
        }

        //Pager
        if (list[0] === "page") {
          window.discountCurrpage = list[1];
        }
      });
    });

    if (loc.loctype) {
      Discount.SetLocationField($(filterInputs.location),loc);
    }
  }
  filterByCategory();
};

//Setup Querystring and replace in URL
const setQuery = (page) => {
  let query = "";
  window.d_reset= true;
  $.each(filterInputs,(k,v)=> {
    let val = v.val();
    //If location is picking current location, get coordinate from attribute
    if (k === "location") {

      let coord = v.attr(Discount.Key.LocationDataLatLng);
      if (v.attr(Discount.Key.LocationDataLatLng).indexOf(",") >= 0) {
        coord = v.attr(Discount.Key.LocationDataLatLng).split(",");
      }
      let loctype = coord.length > 2 ? parseInt(coord[2]) : 0;
      if (isNaN(loctype)) {
        loctype = 0;
      }
      if (loctype === 2) {
        //autocomplete
        query += Discount.GetParam("place",val);
        query += Discount.GetParam("coord",$(v).attr(Discount.Key.LocationDataLatLng));
      }
      if (loctype > 0 && loctype !== 2) {
        query += Discount.GetParam("loctype",loctype);
      }
      if (loctype === 0) {
        //default??
        //console.log("loc type is selected zero" + loctype)
      }
      //console.log("val: " + v.attr(Discount.Key.LocationDataLatLng) + ", " + query);
    } else {
      if (k === "keyword") {
        filterInputs.keyword.val(val.replaceAll("+"," ").replaceAll("  "," ").trim());
      }
      if (k === "distance") {
        //console.log("filter input: " + k+":"+val);
      }
      query += Discount.GetParam(k,val);
    }
  });

  $(categoryList).each(function(){
    query += Discount.GetParam("category",this);
  });

  if (vendor != null) {
    query += Discount.GetParam("vendor",vendor);
  }

  if (vendorName != null) {
    query += Discount.GetParam("vendorName",vendorName);
  }

  //if page exists, create URL
  if(page) {
    page = parseInt(page);
    query += Discount.GetParam("page",page);
  }
  history.replaceState("", "", query);
};

//Display (show/hide) function
const displayControl = ($dist,val,hval,hideshow) => {
  if (typeof hval === "number") {
    val = parseInt(val);
  }
  //console.log(val +"==="+ hval+":"+ (val=== hval))
  if (val === hval) {
    if (hideshow) { $dist.closest(".filter-field").hide(); }
    //$dist.prop("disabled",true);
  } else {
    if (hideshow) { $dist.closest(".filter-field").show(); }
    //$dist.prop("disabled",false);
  }
  $dist.find("option").first().prop("selected",true)
};

//Filter Control for Distance (category & type triggers)
let ignoreDistance = false;

/*REMOVED IN CATEGORY OVERHUAL
const ignoreDistanceDisplayControl = () => {

  //Enable all
  ignoreDistance = false;
  if (Discount.GetParam("vendor")) {
    ignoreDistance = true;
  }
  $(filterInputs.sortby).find("option").prop("disabled",false);
  $(filterInputs.distance).find("option").prop("disabled",false);
  if ($(filterInputs.category).prop("checked").data("ignore-distance") === "yes") {
    ignoreDistance = true;
    $(filterInputs.sortby).find("option").prop("disabled",true).first().prop("disabled",false).prop("selected",true); //disable everything except 1st one and select.
    $(filterInputs.distance).find("option").prop("disabled",true).first().prop("disabled",false).prop("selected",true); //disable everything except 1st one and select.
  }
};
*/

const defaultSortById = 2; //Closest
selectDropdown($(filterInputs.sortby),defaultSortById);

const defaultDistanceId = 1; //1:ALL;2:15KM
const distanceDisplayControl = () => {//console.log("setting up distance filter display");
  //Setup default
  // $(filterInputs.sortby).find("option").each((k,v)=>{
  //   $(v).prop("selected",parseInt($(v).val())===defaultSortById ? true : false);
  // });
  displayControl($(filterInputs.distance),$(filterInputs.sortby).val(),true);
  let distanceId = Discount.GetParam("distance");
  if (!distanceId) {
    distanceId = defaultDistanceId;
  }
  if (ignoreDistance) {
    return;
  }
  if (parseInt($(filterInputs.sortby).val()) === 1) {
    selectDropdown($(filterInputs.distance),distanceId);//console.log("sort by: latest, distance: " + distanceId);
  } else {
    selectDropdown($(filterInputs.distance),distanceId);//console.log("sort by: distance, distance: " + distanceId);
  }
};
distanceDisplayControl();

//Get vendor ID from Querystring and not returning 0 value
const getVendorId = () => {
  const vendorId = Discount.GetParam("vendor");
  return vendorId ? vendorId : null;
};

const setPager = (cls, page, size, max) => {
  const range = 10; //Max range number for pagination area
  const bound = range / 2 - 1; //
  const clsButton = cls + "__button"
  let volume = Math.ceil(max / size);

  let pst = 0;

  if (page >= volume) page = volume - 1;

  if (volume > 1) {
    let pager = "";

    //Prev area
    if (page > bound) {
      pst = page - bound;
     //bigger than bound, prev would show
      if (volume > bound) {
        pager = "<button class='" + clsButton.substring(1) + " prev' value='<' data-page='0'>&ll;</button>";
        pager += "<button class='" + clsButton.substring(1) + " prev' value='<' data-page='" + pst + "'>&lt;</button>";
      }
    }
    let ped = pst + bound * 2;

    if (ped >= volume) {
      ped = volume - 1;
      pst = ped - range + 1;
    }
    for (var p = pst; p <= ped; p++) {
      pager += "<button class='" + clsButton.substring(1) + (p === page ? " selected" : "") + "' value='" + p + "' data-page='"+ (p+1) +"'>" + (p+1) + "</button>";
    }
    //End area

    if (volume > range) {
      //Next range
      if (volume >= page + bound) {

      }
      //Last
      if (page + bound + 1 < volume ) {
        pager += "<button class='" + clsButton.substring(1) + " next' value='>' data-page='" + (ped + 1) + "'>&gt;</button>";
        pager += "<button class='" + clsButton.substring(1) + " last' value='>' data-page='" + volume + "'>&gg;</button>";
      }
    }
    if($(cls).length) {
      $(cls).html(pager);
    } else {
      $(totes.loadmore).after("<div class='" + cls.substring(1) + "'>" + pager + "</div>");
    }

    //Add click event to pager
    $(clsButton).on("click",(item)=>{
      setQuery(item.target.dataset.page);
      setDiscountTotes();
      return false;
    });
  }
};

const setDiscountToteData = () => {
  //Pagination
  //stacked = ($(filterInputs.sortby).val() == 1) ? true : false; //SETS STACK TO DEFAULT LOAD
  stacked = (vendor != null) ? false : true;

  //Reset pager container
  $(totes.loadmore + " + " + totes.pager).remove();

  var trueSort = ($(filterInputs.keyword).val() == "" && $(filterInputs.sortby).val() == "1") ? -1 : $(filterInputs.sortby).val();

  let page = parseInt(Discount.GetParam("page"));
  if (!page || isNaN(page)) page = 1;
  page = page -1; //0 is the 1st page

  Discount.SetDiscountData($(totes.grid),Discount.GetDiscountParams(
    $(filterInputs.keyword).val(), //search
    $(filterInputs.type).val(), //type
    Discount.GetLatLng($(filterInputs.location)), //location
    categoryList, //categories
    trueSort, //sortby
    $(filterInputs.distance).val(), //distance
    vendor, //vendor
    page, //pagenum
    totes.pagesize, //pagesize
    stacked
  ),(data) => {
    //console.log(Array.isArray(data));
    let didata = Array.isArray(data) ? data[0] : data;

    if (typeof data === "object") {
      window.loadedData = didata.discounts;
      //Pager
      const discountsTotal = didata.discounts_total ? didata.discounts_total : totes.pagesize;
      //setPager(totes.pager, page, totes.pagesize, discountsTotal);

      //Filter the results
      filterResults();

    } else {
      console.warn(" Data type isn't returning correctly.Data type: "+ typeof data);
    }
  });

  updateFilterText();

//   const vendorId = getVendorId();
// const coord = Discount.GetLocationInput();

// const obj = [
//   { key:"VendorID",  value:cid,  type:"number",  default:null },
//   { key:"d_searchText",  value:$(filterInputs.keyword).val(),  type:"number",  default:null },
//   { key:"d_TypeID",  value:cid,  type:"number",  default:null },
//   { key:"d_CategoryID",  value:cid,  type:"number",  default:null },
//   { key:"d_CategoryID", value:, type:"string", default:null },
//   { key:"d_sortID", value:lat, type:"string", default:null },
//   { key:"d_distanceID", value:, type:"string", default:0 },
//   { key:"Latitude", value:lng, type:"string", default:null },
//   { key:"Longitude", value:lng, type:"string", default:null },
//   { key:"pageNumber", value:0, type:"number", default:0 },
//   { key:"pageSize", value:100, type:"number", default:100 }];

// const obj =
//   [Discount.getJsonParam( "",vendorId,"string",null),
//   Discount.getJsonParam( "",,"string",null),
//   Discount.getJsonParam( "",$(filterInputs.type).val(),"number",null),
//   Discount.getJsonParam( "",$(filterInputs.category).val(),"number",null),
//   Discount.getJsonParam( "",$(filterInputs.sortby).val(),"number",null),
//   Discount.getJsonParam( "",$(filterInputs.distance).val(),"number",null),
//   Discount.getJsonParam( "",coord.latitude,"string",null),
//   Discount.getJsonParam( "Longitude",coord.longitude,"string",null),
//   Discount.getJsonParam( "pageNumber",0,"number",0),
//   Discount.getJsonParam( "pageSize",100,"number",100)];

};
//Set discount tote list
const setDiscountTotes = () => {
  if (!window.basestatus) {
    setDiscountToteData();
  }
  let count = 0;
  const timer = setInterval(()=> {
    if (window.basestatus === "ready") {
      clearTimeout(timer);
      setDiscountToteData();
    }
    count ++;
  },100);
  count > 3 ? clearTimeout(timer) : "";
};


//Setup filter fields show/hide
//displayControl($(filterInputs.category),$(filterInputs.type).val(),0,true);
//console.log("sort by: "+$(filterInputs.sortby).val() +" dv: "+ $(filterInputs.distance).val())


let timer;
const displayLoading = (loaded) => {

  //if page is loaded, deduct execution count.
  if(loaded) {
    execnt --;
    $(totes.feed).addClass(actcls.loaded);
    $(totes.filter).addClass(actcls.loaded);
    $(totes.subfilter).addClass(actcls.loaded);
    setTimeout(()=>{
      $(totes.feed).removeClass(actcls.loading);
      $(totes.filter).removeClass(actcls.loading);
      $(totes.subfilter).removeClass(actcls.loading);
    },500);
    clearInterval(timer);
  } else {
    execnt ++;
    $(totes.feed).addClass(actcls.loading);
    $(totes.filter).addClass(actcls.loading);
    $(totes.subfilter).addClass(actcls.loading);
    waitForLoading();
  }
};

let execnt = 0;

$.when(Discount.CheckBrowserLocation($(".notification--location__container"), $(filterInputs.location), true)).then(() => {console.log("Checking the browser location...");

  //Init discount filter for actions
  Discount.InitDiscountFilter (
    $(filterInputs.keyword),
    $(filterInputs.type),
    filterLocation,
    $(".discount-filter__dropdown input[name=selectedOptions]").is(":checked"),
    $filterAction,
    () => { //this is action callback
      if (!$(totes.filter).hasClass(actcls.loading)) {
        execnt = 0;
        filterByCategory();
        setDiscountTotes();
        console.log("after discount filter callback");
      } else {
        console.warn("Please wait until load the page and try again.");
        waitForLoading();
        execnt ++;
      }
    }
  );

  //Set filter Default from Query
  setQueryData();

  Discount.SetAutocompleteDefault(filterLocation.modal.find(".select-current"),$(".notification--location__container"),filterLocation, () => {
    //After default is set, filter the tote
    $filterAction.trigger("click");
  });

  //typeDisplayExtraControl();
  //ignoreDistanceDisplayControl();
  //After filter is set, setup process for the totes
  displayLoading(); //display loading icon
  setDiscountTotes();

  //Set location parameters
  Discount.SetLocationLinkParams($(".breadcrumbs,.breadcrumb-mobile"));
});

//Use my location button click
$locationBtn.on("click",function(){
  Discount.GetLocationInfo();
});

/*$(filterInputs.keyword).on("blur",()=> {
  $filterAction.trigger("click");
});

$(filterInputs.keyword).on("change",()=> {
  if($(filterInputs.keyword).val() != "" ){
    $(filterInputs.sortby).val(1);
  }
});
*/

/*Filter: Type
$(filterInputs.type).on("change",()=> {
  displayControl($(filterInputs.category),$(filterInputs.type).val(),0,true);
  typeDisplayExtraControl();
  $filterAction.trigger("click");
});*/

//Filter: Location
$(filterInputs.location).on("focus",()=> {
  $filterAction.trigger("click");
}).on("change",()=> {
  $filterAction.trigger("click");
});

//Filter: Category
$(filterCategory).on("change",()=> {
  filterByCategory();
});

//Filter: Sort By
//distanceDisplayControl();
$(filterInputs.sortby).on("change",()=> {
  distanceDisplayControl();
});
//Sub Sorting area
$subFilter.on("change", function(){
  $filterAction.trigger("click");
});

//Mobile Filters
$filterBtn.on("click",function(){
  if($filterBtn.hasClass(actcls.active)){
    $panel.attr("aria-hidden","true");
    $filterBtn.attr("aria-pressed","false");
    $filterBtn.removeClass(actcls.active);
    $panel.removeClass(actcls.active);
  } else {
    $panel.attr("aria-hidden","false");
    $filterBtn.attr("aria-pressed","true");
    $filterBtn.addClass(actcls.active);
    $panel.addClass(actcls.active);
  }
});

function waitForLoading() {
  timer = setInterval(() => {
    if (execnt) {
      clearInterval(timer);
      if (confirm("Feed is still loading. Would you stop loading?")) {
        displayLoading(true);
        $(totes.grid);
        $(totes.grid).find(".cell").html(localize.get("discount.error.loading")).addClass(actcls.error);
        $(totes.loadmore).hide();
      } else {
        waitForLoading();
      }
    }
  },10000);
};

function updateFilterText(){
  $(".discount-advanced-filter__selected-categories").empty();
  const $catBubble = $(".discount-filter__type button span");
  var bubbleText = (categoryList.length != 0) ? categoryList.length : $catBubble.data("all");
  $(".discount-filter__type button span").text(bubbleText);

  if(vendorName != null){
    $(".discount-advanced-filter__selected-categories").append("<li><button class='vendor-filter-btn' data-value="+Discount.GetParam("vendor")+">"+Discount.GetParam("vendorName")+"</button></li>");
  }
  $(".discount-advanced-filter__selected-categories .vendor-filter-btn").click(function(){
    vendorName = null;
    vendor = null;
    setQuery();
    window.location.reload();
  });

  if(categoryList.length > 0){
    $(filterCategory).each(function(){
      if($(this).is(":checked")){
        $(".discount-advanced-filter__selected-categories").append("<li><button class='cat-filter-btn' data-value="+$(this).val()+">"+$(this).data("label")+"</button></li>");
      }
    });
    $(".discount-advanced-filter__selected-categories .cat-filter-btn").click(function(){
      var dataValue = $(this).data("value");
      $(filterCategory).each(function(){
        if(dataValue == $(this).val()){
          $(this).prop("checked",false);
        }
      });

      filterByCategory();
    });
  }
  
  //Toggle clear filter btn
  if(categoryList.length != 0 || vendorName != null || filterInputs.keyword.val() != ""){
    $(".clear-filter-btn").addClass("active");
  }else{
    $(".clear-filter-btn").removeClass("active");
  }
}

$(".clear-filter-btn").on("click",function(){
  //Clear all filters
  vendorName = null;
  vendor = null;
  $(filterCategory).each(function(){
    $(this).prop("checked",false);
  });
  filterInputs.keyword.val("");
  filterByCategory();
});

function filterByCategory(){
  categoryList.length = 0;

  $(filterCategory).each(function(){
    if($(this).is(":checked")){
     categoryList.push($(this).val());
    }
  });

  //selectDropdown($(filterInputs.sortby),defaultSortById);
  setDiscountTotes();
  //If online only is selected
  /*ignoreDistanceDisplayControl();
  if ($(this).data("ignore-distance") === "yes") {
    selectDropdown($(filterInputs.sortby),1);
  } else {
    selectDropdown($(filterInputs.sortby),defaultSortById);
  }*/

  /*Title update
  if ($(filterInputs.category).prop("selectedIndex") > 0) {
    $(".discount-advanced-filter h2").text($(filterInputs.category).find("option:selected").text());
  } else {
    $(".discount-advanced-filter h2").text($(filterInputs.type).find("option:selected").data("title"));
  }*/

  setQuery();
  updateFilterText();
}

function filterResults(){
  const btnLoad = totes.loadmore;
  $(totes.grid).loadMoreData({
    template: discountItem,
    firstLoad: totes.firstload,
    loadNum: totes.loadnum,
    btnLoad: btnLoad,
    eventPreload: () => {      
      //displayLoading(); //This isn't rendering before the totes appears
    },
    eventBefore: (data) => {
      let ndata = [];
      $.each(data,(key,item) => {
        //item.discount_url = item.discount_url.replace("~/","/");
        //Update distance from -1 to max;
        if (item.discount_distance < 0) {
          item.discount_distance = Discount.Key.DistanceMax;
        }
        //Add detail parameters
        window.d_reset = true;
        let params = Discount.GetParam("location",item.locationItemID) + Discount.GetLocationParam(filterInputs.location);

        if (typeof item.discount_params === "string") {
          item.discount_params = params;
        } else {
          item = Object.assign({"discount_params": params }, item);
        }

        var separator = ',';
        var locAddress = "";

        if (item.discount_streetAddress) locAddress = item.discount_streetAddress;
        if (item.discount_city) locAddress = locAddress + separator + " " + item.discount_city;
        if (item.discount_stateProvince) locAddress = locAddress + separator + " " + item.discount_stateProvince;
        //if (item.discount_country) locAddress = locAddress + separator + " " + item.discount_country;
        if (item.discount_postalCode) locAddress = locAddress + " " + item.discount_postalCode;
        item.discount_fullAddress = locAddress;
        item.discount_url = item.discount_url.replace("~/","/");

        ndata.push(item);
      });

      ndata["lang"] = localize.lang();

      //Stack duplicate cards.
      if(stacked){
        var ndataCombined = [];
        $.each(ndata, function (i, e) {
          if (e.discount_stacked_location_count > 1){
            e.discount_url = "#";
            e.discount_params = "";
            e.discount_more = (isFrench) ? "Voir tous les emplacements" : "See all Locations";
          }
          ndataCombined.push(e);
        });
        return Discount.SortBy(ndataCombined,filterInputs.sortby.val());
      }else{
        //data = Discount.CalculateDistance(data,Discount.GetLatLng(filterInputs.location),filterInputs.distance.val());
        return Discount.SortBy(ndata,filterInputs.sortby.val());
      }
    },
    eventAfter: (container) => {
      //$(filterInputs.keyword).val("");
      //No results
      if(!$(totes.grid).html()) {
        $(totes.grid).html(window.discounts_noResults);
        $(totes.grid).find(".cell").addClass(actcls.noresults);
      } else {
        //Treat unlock special button
        //Discount.SetUnlockSpecialEvent($(".discount-tote"));
        //Calculate distance
        //filterInputs.sortby.val() == 1 ? $(".discount-tote__distance").hide() : $(".discount-tote__distance").show();

        Discount.FormatDistance($(totes.distance));
        $(totes.date).each((k,v) => {
          $(v).text(new Date($(v).text()).toLocaleString("en-US"));
        });
        //bind anchor for modal
        if ($(container).find("a.modal-link").length > 0) {
          $(container).find("a.modal-link").magnificPopup();
        }
        /*if ($(filterInputs.category).prop("checked").data("ignore-distance") === "yes") {
          $(totes.distance).each((k,item)=> {
            $(item).hide();
          });
        }*/
        $(totes.feed).find("."+actcls.inactive).each((k,tote)=> {
          setTimeout(()=> {
            $(tote).removeClass(actcls.inactive);
          },100);
        });

        //Pager: display control
        if ($(totes.loadmore).is(":hidden")) {
          $(totes.loadmore + " + .pager").show();
        } else {
          $(totes.loadmore + " + .pager").hide();
        }
      }
      //It controlled through backend
      //let catimg = new Array();
      // $(".discount-advanced-filter__controls .category option").each((k,v) => {
      //   //console.log(k+":"+v+":"+$(v).data("default-image")+":"+$(v).val());
      //   //catimg[string]
      //   catimg[$(v).val()] = $(v).data("default-image");
      // });
      //$(".discount-tote").each((k,item)=>{
        //$(item).attr("href",$(item).attr("href").replace("~/","/"));
        //set default image for vendor
        // let catid = $(item).find(".discount-tote__cat").data("catid");
        // if (catid) {
        //   $(item).find(".tote__img.no-image img").attr("src",catimg[catid]);
        // }\
      //});

      $(".discount-tote").each(function(){
        if ($(this).attr("data-url") != ""){
          $(this).on("click", function(){
            globalRedirectURL.value = $(this).attr("data-url");
          });
        }
        if($(this).attr("href") == "#"){
          $(this).attr("href",null);
          $(this).addClass("stacked no-link-handling");
          $(this).on("click", function(){
            selectDropdown($(filterInputs.sortby),2);
            $(filterInputs.keyword).val("");
            vendor = $(this).attr("data-vendor");
            vendorName = $(this).find("h2").text();
            $filterAction.trigger("click");
          });
        }
      });

      //$(".tote__img.no-image").removeClass("no-image"); //Remove no image on this list batch
      setTimeout(()=> {
        displayLoading(true);
      },100);
    }
  });
}

